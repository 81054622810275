import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "acciondigital-basic-theme/src/components/layout"
import SEO from "acciondigital-basic-theme/src/components/seo"
import InfoContacto from "acciondigital-basic-theme/src/components/InfoContacto"
import FormBasicoNetlify from "acciondigital-basic-theme/src/components/FormBasicoNetlify"
import ImagenUbicacionMaps from "acciondigital-basic-theme/src/components/ImagenUbicacionMaps"
export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "portada.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

// calle independencia #67 segunda sección 70000
// planta alta oficina #3
const dias = [{ "lun-vie:": ["10:00–18:00"] }, { "sab:": ["10:00–14:00"] }]
const direccion = [
  "Calle Independencia #67",
  "Planta Alta Oficina #3",
  "2da sección",
  "Juchitán, Oax",
]
const telefono = "971 121 4868"
const irMapsLink =
  "https://www.google.com.mx/maps/dir//16.4432733,-95.0145227/@16.4431614,-95.0146384,19.5z/data=!4m2!4m1!3e2"

const asuntos = [
  {
    value: "consultas",
    texto: "Consultas.",
  },
  {
    value: "quejas",
    texto: "Quejas.",
  },
  {
    value: "comentarios",
    texto: "Comentarios.",
  },
]

export default function Home({ data }) {
  // const texto = "hola world!"
  // const goldenTexto = gold(texto)
  const { placeholderImage } = data
  return (
    <Layout>
      <SEO
        title="Inicio"
        imageSource={placeholderImage.childImageSharp.fluid.src}
      />
      <h1 className="text-center p-3">
        <strong>IMPULSO EMPRESARIAL<br/>Istmo</strong>
      </h1>

      <Image fluid={placeholderImage.childImageSharp.fluid} />

      <p className="text-center p-2 blockquote">
        Espacio para la transformación de la comunidad empresarial regional.
      </p>
      <ImagenUbicacionMaps
        titulo="Mapa con la ubicación de siIE."
        linkMaps="https://www.google.com/maps/place/CASA+CABBUR/data=!3m1!4b1!4m2!3m1!1s0x859555dc860dffe5:0x9bc424178a2038c1"
        linkImgMaps="https://maps.googleapis.com/maps/api/staticmap?scale=1&size=1600x900&style=feature:poi.business|visibility:off&style=feature:water|visibility:simplified&style=feature:road|element:labels.icon|visibility:off&style=feature:road.highway|element:labels|saturation:-90|lightness:25&format=jpg&language=es-419&region=MX&markers=color:0x48487f|16.4422069,-95.023577&zoom=16&client=google-presto&signature=kXRH9-AAACYqydlbepYsoaGKm18"
      />

      <InfoContacto
        dias={dias}
        direccion={direccion}
        telefono={telefono}
        irMapsLink={irMapsLink}
      />
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div>
              <h2>Escríbenos</h2>
            </div>
            <FormBasicoNetlify nombre="contacto-v1" asuntos={asuntos} />
          </div>
        </div>
      </div>
      {/* <div>{texto}</div>
      <div>{goldenTexto}</div>
      <ExampleComponent2 text={goldenTexto}>
        <div>aca</div>
      </ExampleComponent2>
      <Foo text="Foo Props" />
      <Bar text="Bar Props" />
      <Nacho text="Nacho Props" /> */}
    </Layout>
  )
}
